import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { getDigitsAfterDecimal } from "../../../../components/helper";

const Item = ({
  item,
  children,
  setVisibleRedeemModal,
  setRedeemingItem,
  setVisibleStakingDetails,
  activeTab
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })} onClick={() => {
        setVisibleStakingDetails(true);
        setRedeemingItem(item);
      }}>
        <div className={styles.row} >
          <div className={styles.col}>
            <div className={styles.currency}>
              <div className={styles.icon}>
                <img src={`${process.env.REACT_APP_IMAGE_URL}/${item?.coinIcon}`} alt="Coin" />
              </div>
              <div className={styles.details}>
                <div className={styles.info}>{item.coinSymbol.toUpperCase()}</div>
                <div className={styles.text}>{item.coinName.toUpperCase()}</div>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{getDigitsAfterDecimal(item?.stakeAmount, item?.amountDecimalPrecision)} <small>{item.coinSymbol.toUpperCase()}</small></div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{getDigitsAfterDecimal(item?.yesterdayProfit, item?.amountDecimalPrecision)} <small>{item.coinSymbol.toUpperCase()}</small></div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{getDigitsAfterDecimal(item?.totalStakeProfit, item?.amountDecimalPrecision)} <small>{item.coinSymbol.toUpperCase()}</small></div>
          </div>
          {/* <div className={styles.col}>
            <div className={styles.info}>{toLocaleStringDateFormat(item?.createdAt)}</div>
          </div> */}
          {activeTab?.toLowerCase() === "open" &&
            <div className={styles.col}>
              <button className={cn("button-small", styles.button)}
                onClick={(e) => {
                  e.stopPropagation();
                  setVisibleRedeemModal(true);
                  setRedeemingItem(item);
                }}
              >
                Redeem
              </button>
            </div>}
        </div>
        <div className={styles.btns}>{children}</div>
      </div>
    </>
  );
};

export default Item;
